<template>
    <div style="position:relative;"  class="crane-and-rigging">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Industrial <span style="color:#00c8d8;">Plant and Equipment Management Software</span></h1>
                <div>
                    <p>Many construction companies attempt to rely solely on "pen and paper" or spreadsheets leading to ineffective maintenance plans. Both methods are difficult to manage, resulting in information being lost or forgotten. Failing to manage plants and equipment can have a serious effect on productivity. The proper utilisation and maintenance of assets and inventory are crucial for improving productivity, and ensuring the safety of workers on-site. Hence, Record TIME is providing an innovative plant and equipment management software solution to simplify this process. With Record TIME management software, you can effortlessly list, schedule, easily access, track    and manage your plant and equipment in real time, ensuring optimal productivity and resource allocation.</p>
               <h3>Make Plant and Equipment Management Simpler and Seamless</h3>
                </div>
                <br><br>
                <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/heavy-civil-software')" class="mr-7 btn-1" elevation="0">
                    Read More
                </v-btn>
                <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                        Get A Demo Now
                    </v-btn><br/><br/>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/features-image/plant-management.webp`" alt="plant and equipment management software"  style="max-width: 90%;">
            </div>
            <div style="clear: both;"></div>
        </div>
                                                                              
        <v-container>
            <div class="gray-container">
            <h2>Easy-to-Use Plant and Equipment Management Software</h2>
            <h3 style="text-align:center;">Create Comprehensive Management Listings with Real-Time Data</h3>
            <p style="text-align:center;">Record TIME allows users to create a comprehensive list of plants (equipment, machinery, vehicles) required for the construction site. Additionally, each plant entry can include details such as equipment type, model, capacity, and any specific attributes. With this management software, users can specify the type of equipment or machinery, for instance: excavators, cranes, bulldozers, concrete mixers, scaffolding, generators, etc with this single source. Moreover, this categorisation helps in asset management and organising the plants based on their functionality and purpose. Users can easily access Record TIME for better plant management as no manual work is required and templates are customisable.</p>
           <center> <img :src="`${publicPath}assets/features-image/benefits-of-plant-management-with-record-time.webp`" alt="plant and equipment listing with details in one dashboard" style="max-width: 90%;"></center>
        </div>  
        </v-container> 
        <v-container>

                    <div class="left-blog1">
                    <br>
                    <h2>Enhance Safety and Compliance: Pre Start Checklist Templates for Plant Equipment</h2>
                    <p style="text-align:left;">Our equipment management software empowers contractors, sub-contractors, and project managers to monitor every aspect of plant and equipment. In addition, it enables real time tracking of equipment utilisation, maintenance management, and workforce allocation.
                    <br>
                    Further, this management system offers a comprehensive list of pre start checklist templates for various types of plants (equipment like <a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist">excavator</a>, vehicle, forklift, truck or ewp). Certainly, users can go through the checklist before using a particular plant to ensure it is in proper working condition and meets the necessary safety standards in real time with this single source.
                    </p>
                    <h3>Make sure that every plant or equipment on your construction work is in good condition and safe to work.</h3>
                    <br><br>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/contact')" class="mr-7 btn-1" elevation="0">
                    Contact Us
                </v-btn>
               </div>
               <div class="right-blog1">
                    <img :src="`${publicPath}assets/features-image/plant-listing.webp`" alt="plant listing with details in one dashboard">
                </div>

                <div style="clear:both;"></div>

            </v-container>

        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>

</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h2 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: left;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 600px);
      padding-right: 15px;
      @media only screen and (max-width: 1280px) {
        max-width: 100%;
      }
    }
    .right-blog1{
      width: 600px;
      float:right;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      img{
        max-width: 600px;
        width: 100%;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
    title: 'Plant and Equipment Management Software for All Industries',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'With Record TIME management software, you can effortlessly list, schedule, easily access, track and manage your plant and equipment in real time.'},
      {name: 'keyword', content: 'plant and equipment management software'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/plant-and-equipment-management-software' }
    
    ]
  },
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
